@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --font-mono: "PT Mono", ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New",
        monospace;

    --landing-nav-height: 56px;

    /*  Colors */
    --text-color: #111;
    --background-color: rgb(249 246 241);
    --background-50-color: rgba(243, 239, 236, 0.75);
    --background-100-color: rgb(238, 234, 231);
    --menu-item-active: #feeddb;
    --menu-item-hover: rgba(254, 237, 219, 0.44);
    --form-select-background: #feeddb;
    --form-select-content-background: #fff;
    --form-select-item-background: rgb(244 244 245);

    /*  Size and spacing */
    --landing-max-width: 768px;
    --app-padding: 1rem;

    /* Funnel */
    --color-funnel-label: var(--color-zinc-900);
    --background-color-funnel-tooltip: var(--color-white);
    --color-funnel-1: var(--color-blue-500);
    --color-funnel-2: var(--color-blue-400);
    --color-funnel-3: var(--color-blue-300);
    --color-funnel-4: var(--color-blue-200);
    --color-funnel-5: var(--color-blue-100);
    --color-funnel-6: var(--color-blue-50);
}

@media (prefers-color-scheme: dark) {
    :root {
        /*  Colors */
        --text-color: #dfdfdf;
        --background-color: #181818;
        --background-50-color: #27272a;
        --background-100-color: #27272a;
        --menu-item-active: #27272a;
        --menu-item-hover: rgba(39, 39, 42, 0.55);
        --form-select-background: rgba(39, 39, 42, 0.55);
        --form-select-content-background: #27272a;
        --form-select-item-background: #181818;

        /* Funnel */
        --color-funnel-label: var(--color-blue-200);
        --background-color-funnel-tooltip: var(--color-zinc-900);
        --color-funnel-1: var(--color-blue-900);
        --color-funnel-2: var(--color-blue-800);
        --color-funnel-3: var(--color-blue-700);
        --color-funnel-4: var(--color-blue-600);
        --color-funnel-5: var(--color-blue-500);
        --color-funnel-6: var(--color-blue-400);
    }
}

html {
    font-family: var(--font-mono);
    color: var(--text-color);
    background: var(--background-color);
    scroll-behavior: smooth;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    box-shadow: 0 0 0px 1000px #fff inset;
}

.smooth {
    @apply transition-colors duration-300;
}

.smooth-all {
    @apply transition-all duration-300;
}

.soft-border {
    @apply border-zinc-400/20 dark:border-zinc-800;
}

.soft-border-hover:hover {
    @apply border-zinc-400/20 dark:border-zinc-700;
}

.soft-border\/50 {
    @apply border-zinc-400/10 dark:border-zinc-800/50;
}

@media screen and (max-device-width: 480px) and (orientation: portrait) and (display-mode: standalone) {
    @supports (-webkit-touch-callout: none) {
        #top-nav-menu {
            padding-top: 60px;
        }

        #bottom-nav-menu {
            padding-bottom: 40px;
        }

        #app-container {
            padding-top: 125px !important;
            padding-bottom: 121px !important;
        }
    }
}

.rta__item--selected {
    opacity: 100 !important;
    @apply text-blue-500;
}
